// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  //Produccion
 serverUrlAppOld: 'https://mart1010.iotecnologias.com.ar/Api/IOT',
  serverUrl: 'https://mart1010.iotecnologias.com.ar/Api/Mobile',
  filesUrlCategories: 'https://mart1010.iotecnologias.com.ar/Files/iconCategoria',
  filesUrlOffers: 'https://mart1010.iotecnologias.com.ar/Files/Ofertas',
  filesUrlProducts: 'https://mart1010.iotecnologias.com.ar/Files/img_productos',
  filesUrlChekout: 'https://mart1010.iotecnologias.com.ar/Api/Mobile/Mobbex_checkout',
  mobbexTest: false,
  

  //Test
  
//  serverUrlAppOld: 'https://mart1010desa.iotecnologias.com.ar/Api/IOT',
//   serverUrl: 'https://mart1010desa.iotecnologias.com.ar/Api/Mobile',
//   filesUrlCategories: 'https://mart1010desa.iotecnologias.com.ar/Files/iconCategoria',
//   filesUrlOffers: 'https://mart1010desa.iotecnologias.com.ar/Files/Ofertas',  
//   filesUrlProducts: 'https://mart1010desa.iotecnologias.com.ar/Files/img_productos',  
//   filesUrlChekout: 'https://mart1010desa.iotecnologias.com.ar/Api/Mobile/Mobbex_checkout',
//   mobbexTest: true,

  firebaseConfig: {
    apiKey: "AIzaSyDLkr93Lo03JFfELd7XxNEgF5Pqa4CwMTE",
    authDomain: "mart1010-f5f31.firebaseapp.com",
    databaseURL: "https://mart1010-f5f31.firebaseio.com",
    projectId: "mart1010-f5f31",
    storageBucket: "mart1010-f5f31.appspot.com",
    messagingSenderId: "1019453075631",
    appId: "1:1019453075631:web:04679e6a4ff10a3ac6645a",
    measurementId: "G-E1QPCGJPE4"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
