import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { HttpGuard } from './core/http.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'advertisements',
    pathMatch: 'full'
  },
  {
    path: 'advertisements', // no-httpguards
    loadChildren: () => import('./modules/advertisements/advertisements.module').then(m => m.AdvertisementsPageModule)
  },
  {
    path: 'my-favourites', // no-httpguards
    loadChildren: () => import('./modules/my-favourites/my-favourites.module').then(m => m.MyFavouritesPageModule)
  },
  {
    path: 'customer-support', // no-httpguards
    loadChildren: () => import('./modules/customer-support/customer-support.module').then(m => m.CustomerSupportPageModule)
  },
  {
    path: 'new-password', // no-httpguards
    loadChildren: () => import('./modules/new-password/new-password.module').then(m => m.NewPasswordPageModule),
  },
  {
    path: 'change-password', // no-httpguards
    loadChildren: () => import('./modules/change-password/change-password.module').then(m => m.ChangePasswordPageModule)
  },
  {
    path: 'delivery-address',
    loadChildren: () => import('./modules/delivery-address/delivery-address.module').then(m => m.DeliveryAddressPageModule),
    data: { noUser: false },
    canActivate: [HttpGuard]
  },
  {
    path: 'delivery-list/:id',
    loadChildren: () => import('./modules/delivery-list/delivery-list.module').then(m => m.DeliveryListPageModule),
    data: { noUser: false },
    canActivate: [HttpGuard]
  },
  {
    path: 'delivery-and-payment',
    loadChildren: () => import('./modules/delivery-and-payment/delivery-and-payment.module').then(m => m.DeliveryAndPaymentPagePageModule),
    data: { noUser: false },
    canActivate: [HttpGuard]
  },
  {
    path: 'delivery-date',
    loadChildren: () => import('./modules/delivery-date/delivery-date.module').then(m => m.DeliveryDatePageModule),
    data: { noUser: false },
    canActivate: [HttpGuard]
  },
  {
    path: 'payment-method',
    loadChildren: () => import('./modules/payment-method/payment-method.module').then(m => m.PaymentMethodPagePageModule),
    data: { noUser: false },
    canActivate: [HttpGuard]
  },
  {
    path: 'help', // no-httpguards
    loadChildren: () => import('./modules/help/help.module').then(m => m.HelpPageModule)
  },
  {
    path: 'products', // no-httpguards
    loadChildren: () => import('./modules/tabs/tabs.module').then(m => m.TabsPageModule),
  },
  {
    path: 'product-detail', // no-httpguards
    loadChildren: () => import('./modules/product-detail/product-detail.module').then(m => m.ProductDetailPageModule),
  },
  {
    path: 'adherents',
    loadChildren: () => import('./modules/adherents/adherents.module').then(m => m.AdherentsPageModule),
    data: { noUser: false },
    canActivate: [HttpGuard]
  },
  {
    path: 'qr/:id',
    loadChildren: () => import('./modules/qr/qr.module').then(m => m.QrPageModule),
    data: { noUser: false },
    canActivate: [HttpGuard]
  },
  {
    path: 'register-success', 
    loadChildren: () => import('./modules/register-success/register-success.module').then(m => m.RegisterSuccessPageModule),
    data: { noUser: false },
    canActivate: [HttpGuard]
  },
  {
    path: 'register-error',
    loadChildren: () => import('./modules/register-success/register-success.module').then(m => m.RegisterSuccessPageModule),
    data: { noUser: true },
    canActivate: [HttpGuard]
  },
  {
    path: 'suggestions', // no-httpguards
    loadChildren: () => import('./modules/suggestions/suggestions.module').then(m => m.SuggestionsPageModule)
  },
  {
    path: 'purchases', // no-httpguards
    loadChildren: () => import('./modules/purchases/purchases.module').then(m => m.PurchasesPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./modules/login/login.module').then(m => m.LoginPageModule),
    data: { noUser: true },
    canActivate: [HttpGuard]
  },
  {
    path: 'user/new',
    loadChildren: () => import('./modules/user/user.module').then(m => m.UserPageModule),
    data: { noUser: true },
    canActivate: [HttpGuard]
  },
  {
    path: 'user/edit',
    loadChildren: () => import('./modules/user/user.module').then(m => m.UserPageModule),
    data: { noUser: false },
    canActivate: [HttpGuard]
  },
  {
    path: 'modify-day/:id',
    loadChildren: () => import('./modules/modify-day/modify-day.module').then(m => m.ModifyDayPageModule),
    data: { noUser: false },
    canActivate: [HttpGuard]
  },

  {
    path: 'orders',
    loadChildren: () => import('./modules/orders/orders.module').then(m => m.OrdersPageModule),
    data: { noUser: false },
    canActivate: [HttpGuard]
  },
  {
    path: 'register',
    loadChildren: () => import('./modules/register/register.module').then(m => m.RegisterPageModule),
    data: { noUser: true },
    canActivate: [HttpGuard]
  },
  {
    path: 'terms-conditions', // no-httpguards
    loadChildren: () => import('./modules/terms-conditions/terms-conditions.module').then(m => m.TermsConditionsPageModule)
  },
 
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
