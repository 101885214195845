import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { Camera } from '@ionic-native/camera/ngx';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { FileTransfer, FileTransferObject } from '@ionic-native/file-transfer/ngx';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';
import { QRScanner } from '@ionic-native/qr-scanner/ngx';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';
import { CalendarModule } from 'ion2-calendar';
import { NgxQRCodeModule } from '@techiediaries/ngx-qrcode';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { CallNumber } from '@ionic-native/call-number/ngx';
import { Diagnostic } from '@awesome-cordova-plugins/diagnostic/ngx';
import { environment } from 'src/environments/environment';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireModule } from '@angular/fire';
const firebaseConfig: any = environment.firebaseConfig;
import { GooglePlus } from '@ionic-native/google-plus/ngx';
import { FirebaseAnalytics } from '@ionic-native/firebase-analytics/ngx';
import { Device } from '@ionic-native/device/ngx';
// import { OneSignal } from '@ionic-native/onesignal/ngx';
import { SignInWithApple, AppleSignInResponse, AppleSignInErrorResponse, ASAuthorizationAppleIDRequest } from '@ionic-native/sign-in-with-apple/ngx';
import { HTTP } from '@awesome-cordova-plugins/http/ngx';
import { PipesModule } from 'src/app/core/pipes/pipes.module';
import { Market } from '@ionic-native/market/ngx';

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    HttpClientModule,
    BrowserModule,
    IonicModule.forRoot({
      mode: 'md'
    }),
    AppRoutingModule,
    CalendarModule,
    NgxQRCodeModule,
    AngularFireModule.initializeApp(firebaseConfig),
    AngularFireAuthModule,
    PipesModule
  ],
  providers: [
    HTTP,
    StatusBar,
    SplashScreen,
    Camera,
    Geolocation,
    SocialSharing,
    QRScanner,
    FileTransfer,
    FileTransferObject,
    InAppBrowser,
    CallNumber,
    Diagnostic,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    GooglePlus,
    FirebaseAnalytics,
    Device,
    // OneSignal,
    SignInWithApple,
    Market,
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
