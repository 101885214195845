import { Component } from '@angular/core';
import { PageService } from './core/page.service';
import { LoadingController, MenuController, Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Location } from '@angular/common';
import * as moment from 'moment';
import { FirebaseAnalytics } from '@ionic-native/firebase-analytics/ngx';
import { Settings } from './app.settings';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';
import { Device } from '@ionic-native/device/ngx';
import { GlobalService } from './core/global.service';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {

  user: any;
  logged: any = false;
  // filesUrl = environment.filesUrl + '/';
  loading: any;
  myAppPages: any = [];
  isLoading = false;
  isLoadingProcessing = false;
  version: any;
  Centro: any;
  devicePlatform: any;
  public platformAndroid: any;
  public platformIos : any;
  public Google = false;
  public Ios = false;

  //botton menu
  public appPages = [
    {
      title: 'Iniciar sesión o crear cuenta',
      url: '/register',
      icon: 'log-out',
      code:'myUser',
      hidden: false,
      disabled: false,
      roles: ['noUser']
    },
    {
      title: 'Mi cuenta',
      url: '/user/edit',
      icon: 'person-circle',
      code:'myUser',
      hidden: false,
      disabled: false,
      roles: ['user']
    },
    {
      title: 'Ver anuncios',
      url: '/advertisements',
      icon: 'pricetag',
      hidden: false,
      disabled: false,
      click: 'logout',
      roles: ['user', 'noUser']
    },
    {
      title: 'Historial de compras',
      url: '/orders',
      icon: 'list',
      hidden: false,
      disabled: false,
      click: 'logout',
      roles: ['user']
    },
    {
      title: 'Cerrar sesión',
      url: '/login',
      icon: 'log-out',
      hidden: false,
      disabled: false,
      roles: ['user'],
      action: 'logout',
    },
  ];

  public appPagesFooter = [
    {
      title: 'Atención al cliente',
      url: '/customer-support',
      hidden: false,
      disabled: false
    },
    {
      title: 'Ayuda en línea',
      url: '/help',
      hidden: false,
      disabled: false,
      click: 'logout'
    },
    {
      title: 'Términos y condiciones',
      url: '/terms-conditions',
      hidden: false,
      disabled: false
    },
    {
      title: 'Dejanos sugerencias',
      url: '/suggestions',
      hidden: false,
      disabled: false,
      click: 'logout'
    },
   
  ];

  constructor(
    public location: Location,
    private pageService: PageService,
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private menu: MenuController,
    public loadingController: LoadingController,
    public firebaseAnalytics: FirebaseAnalytics,
    public socialSharing: SocialSharing,
    public device: Device,
    public global: GlobalService
  ) {
   
    this.initializeApp();

    // Moment configuration
    moment.locale('es');

    this.platform.ready().then( () => {
      this.devicePlatform = this.device?.platform?.toLowerCase();
      if(this.devicePlatform == 'android'){
        this.Google = true;
        this.Ios = false;
      }
      if(this.devicePlatform == 'ios'){
        this.Ios = true;
        this.Google = false;
      }
    });

  }

    ngOnInit() {
      this.global.getUserAsObservable().subscribe(user => {
        this.user = user;
        this.checkMenu();
      });
    }
  

  initializeApp() {

    // (+) Loading

    this.pageService.global.getLoadingAsObservable().subscribe( async (result) => {
      if(result) {
        await this.showLoading();
      } else {
        await this.hideLoading();
      }
    });

    // (-) Loading

    this.platform.ready().then(() => {

      this.pageService.oneSignalInitialConfig();

      // (+) User

      this.user = this.pageService.global.getUser();

      if(this.user.IdCentroDist == 1){
        this.Centro = "Cordoba";
      }else{
        this.Centro = "Caba"
      }
      if(this.user) this.checkUser();

      this.pageService.global.getUserAsObservable().subscribe( (result) => {
        this.user = this.pageService.global.getUser();
      });
      this.pageService.global.checkUser();
      
      // (-) User

      this.splashScreen.hide();

      this.global.getObservable().subscribe((data) => {
        if (data.login === 'OK') {
          setTimeout(() => {
            this.checkMenu();
          }, 1000);
        }
      });

    });

    //let version = this.pageService.global.load(this.pageService.global.settings.storage.version);
    
    let version =  Settings.appVersion.android.code;
    //let version =  Settings.appVersion.ios.code;;
    
    this.version = version ? version : '';

    this.location.onUrlChange( () => {
        this.checkMenu();
    });
  }

  async showLoading(content = 'Procesando...'){
    if(this.isLoading) return;
    this.isLoading = true;
    this.isLoadingProcessing = true;
    this.loading = await this.loadingController.create({
      message: content
    });
    await this.loading.present().then( () => {
      this.isLoadingProcessing = false;
    });
  }

  hideLoading(){
    if(this.isLoadingProcessing) {
      setTimeout(() => {
        this.hideLoading();
      }, 100);
      return;
    }
    if(this.loading) {
      this.loading.dismiss();
    }
    this.isLoading = false;
  }

  checkUser() {
    let endPoint = this.pageService.global.settings.endPoints.login;
    const body = {
      UserName: this.user.Mail,
      //SoloValida = 1 indica que la petición solo validará el estado del usuario
      SoloValida: 1,
      Token: this.pageService.getToken(this.user.Id, '')
    }

    this.pageService.httpPost(endPoint, body)
    .then( (res) => {
      if(res.isError){
        this.pageService.showError(res.data);
        this.pageService.logout();
        this.pageService.navigateRoute('advertisements');
      }
    })
    .catch(e => this.pageService.showError(e));
  }

  checkMenu() {
    if(!this.user){
      this.myAppPages = this.appPages.filter( page => 
        page.roles.includes('noUser')
      )
    }
    else {
      this.myAppPages = this.appPages.filter( page => 
        page.roles.includes('user')
      )
    }
  }

  action(appPage: any) {
    if(appPage.action) {
      this[appPage.action]();
    }
  }


sSahreGogle(){
  if (navigator.share) {
    navigator.share({
     
      url: 'https://play.google.com/store/apps/details?id=iot.app.justmart',
    })
    .then(() => console.log('Compartido con éxito'))
    .catch((error) => console.error('Error al compartir', error));
  }
}



  sSahreIos(){
    var options = {
      message: 'No te quedes afuera de Justmart',
      url: 'https://apps.apple.com/gb/app/mart1010/id1483121410?ign-mpt=uo%3D2',
    };
    this.socialSharing.shareWithOptions(options);}

  logout() {
    this.pageService.logout();
    this.pageService.navigateRoute('advertisements');
  }

}
