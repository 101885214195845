import { Injectable } from '@angular/core';
import { Settings } from '../app.settings';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})


export class GlobalService {

  private fooSubject = new Subject<any>();

  public settings = Settings;

  public loadingBehaviorSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public loading = false;

  public userBehaviorSubject: BehaviorSubject<any> = new BehaviorSubject(null);
  public user: any;

  public objects = {};
  public objectsBehaviorSubject = {};

  public totalQuantityProducts: any;

  constructor() {
    this.loadUser();
    // this.userBehaviorSubject = new BehaviorSubject(false);
  }

  // (+) User

  /**
   * Save user
   */
  saveUser(user: any, stop = false) {
    localStorage.setItem( this.settings.storage.user, JSON.stringify( user ) );
    this.user = user;
   // console.log( stop, 'stop')
    if (!stop) {
      this.userBehaviorSubject.next( user );
    }
  }

  isUser() {
    return this.user && this.user.roles.includes('user');
  
  }

  /**
   * Get user
   */
  getUser() {
    return this.user;
  }

  /**
   * Get user
   */
  isUserLogged() {
    return !!this.user;
  }

  /**
   * Remove user
   */
  removeUser() {
      localStorage.removeItem( this.settings.storage.user );
      this.user = null;
      this.userBehaviorSubject.next(null);
  }

  /**
   * Get observable
   */
  getUserAsObservable(): Observable<any> {
    return this.userBehaviorSubject.asObservable();
  }

  /**
   * Check user
   */
  checkUser() {
    const u = this.loadUser();
      this.userBehaviorSubject.next(u);
    return this.user;
  }

  /**
   * Load user
   */
  loadUser() {
    const u = localStorage.getItem(this.settings.storage.user);
    this.user = u ? JSON.parse(u) : null;
    this.userBehaviorSubject.next(this.user);
    return this.user;
  }
  // (-) User


  // (+) Generic objects

  /**
   * Save object
   */
  save( key, object ) {
    localStorage.setItem( key, JSON.stringify( object ) );
    this.objects[key] = object;
    if(!this.objectsBehaviorSubject[key])
      this.objectsBehaviorSubject[key] = new BehaviorSubject(true);
    else
      this.objectsBehaviorSubject[key].next( true );
  }

  /**
   * Get object
   */
  get( key ) {
    return this.objects[key];
  }

  /**
   * Get object
   */
  exists( key ) {
    return this.objects[key] ? true : false;
  }

  /**
   * Remove object
   */
  remove( key ) {
    localStorage.removeItem( key );
    delete this.objects[key];
    if(this.objectsBehaviorSubject[key]) this.objectsBehaviorSubject[key].next(false);
  }

  /**
   * Get object as observable
   */
  getAsObservable( key ): Observable<any> {
    if(!this.objectsBehaviorSubject[key])
      this.objectsBehaviorSubject[key] = new BehaviorSubject(true);
    return this.objectsBehaviorSubject[key].asObservable();
  }

  /**
   * Check object
   */
  check( key ) {


    const v = this.load( key );
    let exists = false;
    if( v ) exists = true;

    if(!this.objectsBehaviorSubject[key])
      this.objectsBehaviorSubject[key] = new BehaviorSubject(exists);
    else
      this.objectsBehaviorSubject[key].next(exists);

    return this.objects[key];
  }

  /**
   * Load object
   */
  load( key ) {
    const o = localStorage.getItem( key );
    if( o )
      this.objects[key] = JSON.parse( o );
    else
      delete this.objects[key];
    return this.objects[key];
  }

  /**
   * Pop
   */
  pop( key ) {
    const v = this.load( key );
    this.remove( key );
    return v;
  }


  // (-) Generic objects


  // (+) Loading

  /**
   * Show
   */
  showLoading() {
    this.loading = true;
    this.loadingBehaviorSubject.next( true );
  }

  /**
   * Show
   */
  hideLoading() {

    this.loading = false;
    this.loadingBehaviorSubject.next( false );
  }

  /**
   * Is showing
   */
  isLoading() {
    return this.loading;
  }

  /**
   * Get loading observable
   */
  getLoadingAsObservable(): Observable<any> {
    return this.loadingBehaviorSubject.asObservable();
  }

  // (-) Loading

  saveTotalQuantityProducts(totalQuantityProducts: any, stop = false) {
    localStorage.setItem( this.settings.storage.totalQuantityProducts, JSON.stringify( totalQuantityProducts ) );
    this.totalQuantityProducts = totalQuantityProducts;
    if (stop) return;
    if(!this.objectsBehaviorSubject[this.settings.keys.totalQuantityProducts])
      this.objectsBehaviorSubject[this.settings.keys.totalQuantityProducts] = new BehaviorSubject(true);
    else
      this.objectsBehaviorSubject[this.settings.keys.totalQuantityProducts].next( true );
  }

  getTotalQuantityProducts() {
    return this.totalQuantityProducts;
  }

  removeTotalQuantityProducts() {
    localStorage.removeItem( this.settings.storage.totalQuantityProducts );
    this.totalQuantityProducts = null;
    this.objectsBehaviorSubject[this.settings.keys.totalQuantityProducts].next(false);
  }


  getObservable(): Subject<any> {
    return this.fooSubject;
}
}
